import { map, mergeMap, catchError, concatMap, startWith } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs';
import { orderInitRequest, orderTicketsRequest, setOrder, clearOrder, orderSeasonPassTicketsRequest, orderSeasonPassTicketsPh2Request } from './actions';

import {
  ORDER_INIT_REQUEST,
  ORDER_TICKETS_REQUEST,
  ORDER_REQUEST,
  ORDER_CANCEL_REQUEST,
  ORDER_SEASONPASS_TICKETS_REQUEST,
  ORDER_SEASONPASS_TICKETS_PH2_REQUEST
} from './types';
import { createToast, handleErrors, setLoader, setErrorApi, setSesseionsSeatPlan, setSeatPlan, setSesseionsSeatPlanOrder, orderSessionRequestSuccess } from './deps';


/* TODO: move implementation of order initialization to backend */
export const makeOrder = ($action, $state) => {
  return $action.pipe(
    ofType(ORDER_REQUEST),
    map(({ payload }) => {
      const { order: isOrderCreated } = $state.value;
      const { userSessionId, cinemaId, sessionId, tickets, email, permotionId, sessionSeatPlanValue, sessionBookingCountData, isAboId, aboId } = payload;

      
return !isOrderCreated
        ? orderInitRequest(cinemaId, sessionId, tickets, email, permotionId, sessionSeatPlanValue, sessionBookingCountData, isAboId, aboId) :
        email && permotionId ? orderSeasonPassTicketsRequest(email, sessionId, permotionId, sessionSeatPlanValue, sessionBookingCountData) : (isAboId && aboId) ?
          orderSeasonPassTicketsPh2Request(userSessionId, sessionId, tickets, aboId) : orderTicketsRequest(sessionId, tickets);
    })
  );
};

export const initOrder = ($action, $state, { api }) => {
  const $api = api.getModuleByName('orders');

  
return $action.pipe(
    ofType(ORDER_INIT_REQUEST),
    mergeMap(action => {
      const { cinemaId, sessionId, tickets, email, permotionId, sessionSeatPlanValue, sessionBookingCountData, isAboId, aboId } = action.payload;

      return from($api.initOrder(cinemaId)).pipe(
        concatMap(({ userSessionId }) =>
          [
            isAboId && aboId ? orderSeasonPassTicketsPh2Request(userSessionId, sessionId, tickets, aboId)
              : !email ? orderTicketsRequest(sessionId, tickets, userSessionId) : orderSeasonPassTicketsRequest(email, userSessionId, sessionId, permotionId, sessionSeatPlanValue, sessionBookingCountData),
            setLoader(false)
          ]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),
        startWith(setLoader(true))
      );
    })
  );
};

export const orderTickets = ($action, $state, { api }) => {
  const $api = api.getModuleByName('orders');
  const expectedErrors = {
    PAYMENT: {
      type: 'PAYMENT',
      code: 1
    },
    SEATPLAN: {
      type: 'SEATPLAN',
      code: 4
    }
  };

  return $action.pipe(
    ofType(ORDER_TICKETS_REQUEST),
    mergeMap(action => {
      const { sessionId, tickets, userSessionId } = action.payload;
      const {
        sessions: { wizard }
      } = $state.value;

      const orderId = userSessionId || $state.value.order.userSessionId;

      return from($api.orderTickets(orderId, sessionId, tickets, wizard)).pipe(
        concatMap(({ seatPlan, order, errorMessage }) => {
          const actions = [setSeatPlan(seatPlan), setOrder(order), setLoader(false)];

          if (errorMessage) {
            return [createToast('warning', errorMessage), setLoader(false)];
          }

          return actions;
        }),

        ...handleErrors(action),
        catchError(err => {
          const actions = [createToast('warning', err.message.replace(':', '')), setLoader(false)];

          if (Object.keys(expectedErrors).indexOf(err.type) > -1) {
            actions.push(
              setErrorApi({
                ...err,
                expectedErrors
              })
            );
          }

          return of(...actions);
        }),

        startWith(setLoader(true))
      );
    })
  );
};

export const orderSessionTickets = ($action, $state, { api }) => {
  const $api = api.getModuleByName('orders');
  const expectedErrors = {
    PAYMENT: {
      type: 'PAYMENT',
      code: 1
    },
    SEATPLAN: {
      type: 'SEATPLAN',
      code: 4
    }
  };

  return $action.pipe(
    ofType(ORDER_SEASONPASS_TICKETS_REQUEST),
    mergeMap(action => {
      const { email, userSessionId, sessionId, permotionId, sessionSeatPlanValue, sessionBookingCountData } = action.payload;
      const {
        sessions: { wizard }
      } = $state.value;

      const orderId = userSessionId || $state.value.order.userSessionId;

      
return from($api.orderSessionTickets(orderId, wizard, sessionId, permotionId, sessionSeatPlanValue, sessionBookingCountData, email)).pipe(
        concatMap(({ seatPlan, order, errorMessage }) => {
          const actions = [setSeatPlan(seatPlan), setOrder(order), setLoader(false)];

          if (errorMessage) {
            return [createToast('warning', errorMessage), setLoader(false)];
          }
          
return actions;
        }),

        ...handleErrors(action),
        catchError(err => {
          const actions = [createToast('warning', err.message.replace(':', '')), setLoader(false)];

          if (Object.keys(expectedErrors).indexOf(err.type) > -1) {
            actions.push(
              setErrorApi({
                ...err,
                expectedErrors
              })
            );
          }

          return of(...actions);
        }),

        startWith(setLoader(true))
      );
    })
  );
};
export const orderSessionTicketsPh2 = ($action, $state, { api }) => {
  const $api = api.getModuleByName('orders');
  const expectedErrors = {
    PAYMENT: {
      type: 'PAYMENT',
      code: 1
    },
    SEATPLAN: {
      type: 'SEATPLAN',
      code: 4
    }
  };

  return $action.pipe(
    ofType(ORDER_SEASONPASS_TICKETS_PH2_REQUEST),
    mergeMap(action => {
      const { userSessionId, tickets, sessionId, aboId } = action.payload;
      const {
        sessions: { wizard }
      } = $state.value;
      const orderId = userSessionId || $state.value.order.userSessionId;

      
return from($api.orderSessionTicketsPh2(orderId, wizard, tickets, sessionId, aboId)).pipe(
        concatMap(({ seatPlan, order, errorMessage }) => {
          const actions = [setSeatPlan(seatPlan), setOrder(order), setLoader(false)];

          if (errorMessage) {
            return [createToast('warning', errorMessage), setLoader(false)];
          }
          
return actions;
        }),

        ...handleErrors(action),
        catchError(err => {
          const actions = [createToast('warning', err.message.replace(':', '')), setLoader(false)];

          if (Object.keys(expectedErrors).indexOf(err.type) > -1) {
            actions.push(
              setErrorApi({
                ...err,
                expectedErrors
              })
            );
          }

          return of(...actions);
        }),

        startWith(setLoader(true))
      );
    })
  );
};

export const cancelOrder = ($action, $state, { api }) => {
  const $api = api.getModuleByName('orders');

  return $action.pipe(
    ofType(ORDER_CANCEL_REQUEST),
    mergeMap(action => {
      const { payload } = action;

      return from($api.cancelOrder(payload)).pipe(map(clearOrder));
    })
  );
};
