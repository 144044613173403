const movies = {
  pattern: "/film",
  name: "Movies",
  tracked: true
};

let bonusCardPathName = "";

switch (process.env.REACT_APP_COUNTRY) {
  case "svn":
    bonusCardPathName = "/info/bonus-kartica";
    break;
  case "aut":
    bonusCardPathName = "/club";
    break;
  default:
    bonusCardPathName = "/info/bonus-card";
    break;
}

export default {
  fallback: movies,

  /**
   * Movies
   */
  movies,
  movieDetails: {
    pattern: "/film/:id",
    name: "Movie Details",
    tracked: true
  },

  /**
   * Custom Start
   */
  start: {
    pattern: "/film?category=upcoming&date=all",
    name: "Coming Soon",
    tracked: true
  },

  /**
   * Cinemas
   */
  cinemas: {
    pattern: process.env.REACT_APP_COUNTRY === "svn" ? "/kina" : "/cinemas",
    name: "Cinemas",
    tracked: true
  },
  boxMenu: {
    pattern: '/boxmenu',
    name: 'BoxMenu',
    tracked: true
  },
  concessionModal: {
    pattern: '/concessionModal',
    name: 'ConcessionModal',
    tracked: true
  },
  cinemaDetails: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn" ? "/kina/:id" : "/cinemas/:id",
    name: "Cinema Details",
    tracked: true
  },

  /**
   * Auth
   */
  login: {
    pattern: "/sign-in",
    name: "Login",
    tracked: true
  },
  userRegisterFinish: {
    pattern: "/user/register/finish/",
    name: "Login2",
    tracked: true
  },
  registration: {
    pattern: "/sign-up",
    name: "Registration",
    tracked: true
  },
  ffcSignUp: {
    pattern: "/ffcsign-up",
    name: "ffc sign-up",
    tracked: true
  },
  resetPassword: {
    pattern: "/password-reset",
    name: "Reset Password",
    tracked: true
  },
  userPasswortAendern: {
    pattern: "/user/passwort-aendern/",
    name: "Reset Password",
    tracked: true
  },
  refundTickets: {
    pattern: "/refund-tickets",
    name: "Refund Tickets",
    tracked: true
  },

  /**
   * Search
   */
  search: {
    pattern: "/search",
    name: "Search",
    tracked: true
  },

  /**
   * Info
   */
  info: {
    pattern: "/info",
    name: "Info"
  },
  impressum: {
    pattern: process.env.REACT_APP_COUNTRY === "svn" ? '/info/osnovne-informacije' : '/content/impressum',
    // '/info/impressum'
    name: 'Impressum',
    tracked: true
  },
  privacy: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn"
        ? "/info/politika-zasebnosti"
        : "/info/privacy-policy",
    name: "Privacy Policy",
    tracked: true
  },
  legal: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn"
        ? "/info/pravila-poslovanja"
        : "/info/legal-info",
    name: "Legal Info",
    tracked: true
  },
  b2b: {
    pattern: "/info/b2b",
    name: "B2B",
    tracked: true
  },
  priceList: {
    pattern: "/info/price-list/:slug",
    name: "Price List",
    tracked: true
  },
  gastorPriceList: {
    pattern: "/info/gastro-price-list/:slug",
    name: "Price List",
    tracked: true
  },
  priceListt: {
    pattern: "/info/price-list/:slug/:secondarySlug",
    // pattern: '/info/price-list-detail/:slug',
    name: "Price List",
    tracked: true
  },
  bonusCardInfo: {
    pattern: bonusCardPathName,
    name: "Bonus Card",
    tracked: true
  },
  giftCardInfo: {
    pattern: "/info/gift-card",
    name: "Gift Card",
    tracked: true
  },
  competitionInfo: {
    pattern: "/info/competition",
    name: "Competition",
    tracked: true
  },
  competitionDetail: {
    pattern: "/info/competition-detail/:id",
    name: "Competition Detail",
    tracked: true
  },
  familyFilmClub: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn"
        ? "/info/druzinski-filmski-klub"
        : "/info/family-film-club",
    name: "Family Film Club",
    tracked: true
  },
  birthdayRoom: {
    pattern: "/info/birthday-room",
    name: "Birthday Room",
    tracked: true
  },
  hallInfo: {
    pattern: "/info/hallinfo/:id",
    name: "Hall Info",
    tracked: true
  },

  /**
   * Club
   */

  club: {
    pattern: "/club",
    name: "Club"
  },

  /**
   * Purchase
   */
  purchase: {
    pattern: "/purchase",
    name: "Purchase"
  },
  wizard: {
    pattern: "/purchase/wizard/:movieCinemaId",
    name: "Wizard"
  },
  sessions: {
    // pattern: '/purchase/wizard/:movieCinemaId',
    // https://www.cineplexx.at/tickets/1003/abo/0000000086/select?email=hitzingerel@aon.at
    pattern: `/tickets/:cinemaId/abo/:promotionId/select`,
    name: "Sessions Pass"
  },
  stepTickets: {
    pattern: "/purchase/wizard/:movieCinemaId/tickets",
    name: "Purchase Ticket Selection",
    tracked: true
  },
  stepSeats: {
    pattern: "/purchase/wizard/:movieCinemaId/seats",
    name: "Purchase Seats Selection",
    tracked: true
  },
  stepSnacksAndDrinks: {
    pattern: '/purchase/wizard/:movieCinemaId/snacks',
    name: 'Purchase Snacks and Drinks Selection',
    tracked: true
  },
  stepCheckout: {
    pattern: "/purchase/wizard/:movieCinemaId/checkout",
    name: "Purchase Checkout",
    tracked: true
  },
  pickupSnacks: {
    pattern: '/account/concession/prepare/:movieCinemaId/:bookingId',
    name: 'PickupSnacks',
    tracked: true
  },
  paymentSuccess: {
    pattern:
      process.env.REACT_APP_COUNTRY === "bih" ||
        process.env.REACT_APP_COUNTRY === "bil" ||
        process.env.REACT_APP_COUNTRY === "srb" ||
        process.env.REACT_APP_COUNTRY === "rks" ||
        process.env.REACT_APP_COUNTRY === "mkd" ||
        process.env.REACT_APP_COUNTRY === "alb"
        ? "/purchase/payment/success/:userSessionId"
        : "/purchase/payment-success/:userSessionId",
    name: "Payment Success",
    tracked: true
  },
  paymentFailure: {
    pattern:
      process.env.REACT_APP_COUNTRY === "bih" ||
        process.env.REACT_APP_COUNTRY === "bil" ||
        process.env.REACT_APP_COUNTRY === "srb" ||
        process.env.REACT_APP_COUNTRY === "rks" ||
        process.env.REACT_APP_COUNTRY === "mkd" ||
        process.env.REACT_APP_COUNTRY === "alb"
        ? "/purchase/payment/error/:id"
        : "/purchase/payment-failure/:id",
    name: "Payment Failure",
    tracked: true
  },
  reservationSuccess: {
    pattern: "/purchase/reservation-success/:userSessionId",
    name: "Reservation Success",
    tracked: true
  },

  /**
   * Account
   */
  account: {
    pattern: "/account",
    name: "My Account"
  },
  user: {
    pattern: "/account/user",
    name: "My Account Personal Data",
    tracked: true
  },
  userEdit: {
    pattern: "/account/user/edit",
    name: "My Account Edit Personal Data",
    tracked: true
  },
  userChangePassword: {
    pattern: "/account/user/change-password",
    name: "My Account Change Password",
    tracked: true
  },
  tickets: {
    pattern: "/account/tickets",
    name: "My Account My Tickets",
    tracked: true
  },
  ticketsBought: {
    pattern: "/account/tickets/bought/:cinemaId/:bookingId",
    name: "My Account Ticket Details",
    tracked: true
  },
  ticketsReserved: {
    pattern: "/account/tickets/reserved/:cinemaId/:bookingId",
    name: "My Account Reservation Details",
    tracked: true
  },
  history: {
    pattern: "/account/history",
    name: "My Account History",
    tracked: true
  },
  bonusCard: {
    pattern: "/account/bonus-card",
    name: "My Account Bonus Card",
    tracked: true
  },
  xXtraCard: {
    pattern: "/account/xXtra-card",
    name: "My Account xXtra Card",
    tracked: true
  },
  giftCard: {
    pattern: "/account/gift-card",
    name: "My Account Gift Card",
    tracked: true
  },
  whatchList: {
    pattern: "/account/watch-list",
    name: "My Account Watch List",
    tracked: true
  },

  /**
   * Events
   */
  events: {
    pattern: process.env.REACT_APP_COUNTRY === "svn" ? "/dogodki" : "/events",
    name: "Events",
    tracked: true
  },
  eventDetails: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn" ? "/dogodki/:id" : "/events/:id",
    name: "Event Details",
    tracked: true
  },
  fixedEventDetails: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn" ? "/dogodki/306" : "/events/306",
    name: "Event Details",
    tracked: true
  },
  newsletterEvent: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn"
        ? "/dogodki/newsletter"
        : "/event/newsletter",
    name: "Newsletter Events",
    tracked: true
  },

  /**
   * technologies
   */
  technologies: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn"
        ? "/tehnologije"
        : "/technologies",
    name: "Technology Details",
    tracked: true
  },
  technologyDetails: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn"
        ? "/tehnologije/:id"
        : "/technologies/:id",
    name: "Cinema Details",
    tracked: true
  },

  /**
   * contact
   */
  faq: {
    pattern: process.env.REACT_APP_COUNTRY === "svn" ? "/kontakt" : "/faqs",
    name: "FAQs Page"
  },
  landing: {
    pattern: "/content/:slug", ///content.node
    name: "Landing Page"
  },

  /**
   * cinema rent
   */
  cinemaRent: {
    pattern: process.env.REACT_APP_COUNTRY === 'svn' ? '/izdavanje-kina' : '/cinema-rent',
    name: 'Cinema Rent',
    tracked: true
  },
  cinemaRentInquiry: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn"
        ? "/izdavanje-kina/inquiry"
        : "/cinema-rent/inquiry",
    name: "Cinema rent inquiry",
    tracked: true
  },

  /**
   * cinema advertising
   */
  cinemaAdvertising: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn"
        ? "/oglasevanje"
        : "/cinema-advertising",
    name: "Cinema advertising",
    tracked: true
  },

  /**
   *  School cinema
   */
  schoolCinema: {
    pattern: "/content/schulkino",
    name: "Schulkino",
    tracked: true
  },

  /**
   * Newsletter registration
   */
  newsletterRegisration: {
    pattern: "/newsletter",
    name: "Newsletter registration",
    tracked: true
  },
  newsletterRegisrationForCinemaRent: {
    pattern: "/kino-mieten-newsletter",
    name: "Newsletter registration",
    tracked: true
  },

  newsletterRegisrationVerify: {
    pattern: "/newsletter/:slug",
    name: "Newsletter Registration Verify",
    tracked: true
  },

  /**
   *  portrait
   */
  portrait: {
    pattern:
      process.env.REACT_APP_COUNTRY === "svn" ? "/podjetju" : "/portrait",
    name: "Portrait",
    tracked: true
  },
  /**
   *  Survey
   */
  survey: {
    pattern: "/befragung",
    name: "Survey",
    tracked: true
  },

  /**
   *  jobs
   */
  jobs: {
    // pattern: "/content/jobs",
    pattern: "/events/wir-suchen-dich",
    name: "Jobs",
    tracked: true
  },

  /**
   * Others
   */
  error: {
    pattern: "/error",
    name: "Error"
  },
  no_internet: {
    pattern: "/no-internet",
    name: "No Internet"
  },
  style: {
    pattern: "/style"
  },
  cookies: {
    pattern: "/pdfs/cookie_policy-:lang.pdf"
  }
};
