import { Button, Checkbox, Container, Image, InputNumber, Label, Modal, Radio } from 'app/components';
import { SectionEntities } from 'app/components/Entity';
import { useConfig, useModal } from 'app/hooks';
import React, { useEffect, useState } from 'react';
import { MainSection, SelectionHeadingSelection, SelectionItemSelection, SelectionSelection, SubSection, SubSection1, SubSection2, ButtonSection, SelectionMobileBoxMenu } from './styles';
import { RowButton } from '../styles';
import CrossIcon from '../../../assets/images/close.png';
import { MainSectionMobileView } from '../ConcessionModal/styles';
import { useSelector } from 'react-redux';

const BoxMenu = ({ fetchComboItem, comboId, setConcessionData, languageContents, languageContentsForPopup, isModalOpen, windowSizes, setIsModalOpen, setConcessionComboItem }) => {
  const {
    api: { endpoint },
    currencyForCountry
  } = useConfig();
  const modal = useSelector(state => state.layout.modals['BoxMenu']);
  const [BoxMenu, showBoxMenu, hideBoxMenu] = useModal('BoxMenu');
  const [windowSize, setWindowSize] = useState();
  const [summeDetails, setSummeDetails] = useState(false);
  const [isExpanded, toggleExpand] = useState(false);
  const comboItemData = useSelector(state => state?.concessions?.comboItemData);
  const [selectedItems, setSelectedItems] = useState({});
  const selectedItemsData = useSelector(state => state?.concessions?.concessionData);
  const [selectedArrayItems, setSelectedArrayItems] = useState([]);

  const handleSummeDetail = () => {
    toggleExpand(!isExpanded);
    setSummeDetails(!summeDetails);
    setIsModalOpen(!isModalOpen);
  };
  const handleCheckboxChange = (itemIdx, description, altItem, quantity) => {
    setSelectedItems(prevSelectedItems => {
      const updatedSelectedItems = { ...prevSelectedItems };

      if (!updatedSelectedItems[description]) {
        updatedSelectedItems[description] = [];
      }

      const isSelected = updatedSelectedItems[description].some(item => item.Id === altItem.Id);

      if (isSelected) {
        updatedSelectedItems[description] = updatedSelectedItems[description].filter(item => item.Id !== altItem.Id);
      } else {
        // Ensure only one item can be selected per itemIdx
        updatedSelectedItems[description] = [{
          ItemId: altItem.Id,
          Description: altItem.Description,
          quantity: quantity || 1,
          ParentSaleItem: {
            ItemId: itemIdx
          }
        }];
      }

      return updatedSelectedItems;
    });
  };

  const handleDirectSelection = (item) => {
    setSelectedItems(prevSelectedItems => {
      const updatedSelectedItems = { ...prevSelectedItems };

      if (!updatedSelectedItems[item.description]) {
        updatedSelectedItems[item.description] = [];
      }

      const isSelected = updatedSelectedItems[item.description].some(selectedItem => selectedItem.ItemId === item.id);

      if (isSelected) {
        updatedSelectedItems[item.description] = updatedSelectedItems[item.description].filter(selectedItem => selectedItem.ItemId !== item.id);
      } else {
        updatedSelectedItems[item.description] = [{
          ItemId: item.id,
          Description: item.description,
          quantity: item.quantity
        }];
      }

      return updatedSelectedItems;
    });
  };

  useEffect(() => {
    const concessionData = Object.values(selectedItems).flat();
    if (concessionData.length > 0) {
      setSelectedArrayItems(concessionData);
    } else {
      setSelectedArrayItems([]);
    }
  }, [selectedItems]);

  const updateItemQuantity = (item, description) => (newQuantity) => {
    const session = 'comboSession';
    const updatedItems = {
      normalSession: { ...item.normalSession },
      comboSession: { ...item.comboSession }
    };
    if (newQuantity === 0) {
      delete updatedItems[session][description];
    } else {
      updatedItems[session][description] = {
        ...item.comboSession[description],
        quantity: newQuantity,
      };
    }
    setConcessionData(updatedItems);
  };

  const handleConcessionComboOnServer = () => {
    if (!selectedItemsData.comboSession) {
      selectedItemsData.comboSession = {};
    }
    selectedItemsData.comboSession[comboId?.itemId]["packageChildItems"] = selectedArrayItems;
    setConcessionData(selectedItemsData);
    setIsModalOpen(!isModalOpen);
  }

  useEffect(() => {
    setConcessionComboItem(null);
  }, []);

  useEffect(() => {
    fetchComboItem(comboId)
  }, [comboId]);

  useEffect(() => {
    setWindowSize(window.innerWidth);
  }, [window]);

  window.addEventListener('resize', function () {
    setWindowSize(window.innerWidth);
  }, true);

  useEffect(() => {
    if (isModalOpen) {
      if (windowSizes >= 992) {
        showBoxMenu();
      }
      else {
        setSummeDetails(!summeDetails);
        toggleExpand(!isExpanded)
      }
    }
  }, [isModalOpen, modal]);

  useEffect(() => {
    if (modal === false) {
      setIsModalOpen(!isModalOpen);
    }
  }, [modal]);
  const quantity = selectedItemsData['comboSession'][comboId?.itemId]?.quantity;
  const priceInCents = (selectedItemsData['comboSession'][comboId?.itemId]?.priceInCents / 100).toFixed(2);
  const description = selectedItemsData['comboSession'][comboId?.itemId]?.description;
  const img = selectedItemsData['comboSession'][comboId?.itemId]?.imagePath;
  return (
    <SectionEntities>
      <Container>
        {
          windowSize >= 992 ?
            (
              <div className='boxMenu_title'>
                {
                  comboItemData?.length &&
                  <Modal
                    isOpen={BoxMenu}
                    close={hideBoxMenu}
                    title={"Select items"}
                    isBoxMenu={true}
                  >
                    <MainSection>
                      <SubSection>
                        <SubSection1>
                          <div className='image-section'>
                            <Image className="rewardImage" src={`${endpoint}${img}`} />
                          </div>
                          <div className='content-section'>
                            <div className='content-heading'>{description}</div>
                            {comboItemData?.length && comboItemData?.map((item, index) => (
                              <div className='content-subHeading'>
                                <div>{quantity * item.quantity} X {item?.[languageContents?.description]}</div>
                              </div>
                            ))}
                          </div>
                        </SubSection1>
                        <SubSection2>
                          <div>
                            <div className='total-section'>
                              <div className='boxMenu_inputField'>
                                <InputNumber
                                  min={1}
                                  value={quantity || 0}
                                  onChange={updateItemQuantity(selectedItemsData, comboId?.itemId)}
                                />
                              </div>
                              <div className='total-sub-section'>
                                <span className='sub-section1'>{quantity} X {currencyForCountry} {priceInCents}</span>
                                <span className='sub-section2'> {currencyForCountry} {quantity * priceInCents}</span>
                              </div>
                            </div>
                          </div>
                        </SubSection2>
                      </SubSection>
                    </MainSection>
                    <SelectionSelection>
                      {comboItemData?.length && comboItemData?.map((item, index) => (
                        <>
                          <SelectionHeadingSelection key={item?.idx} style={{ marginTop: index === 0 ? "0px" : "24px" }}>
                            <div className='header-section'>
                              <div className='number-section'><span className='number-sub-section'>{index + 1}</span></div>
                              <span className='heading-content-section'>
                                <p className='heading-content-subSection1'>{item?.[languageContents?.description]}</p>
                                <p className='heading-content-subSection2'>only {item.quantity} item</p>
                              </span>
                            </div>
                          </SelectionHeadingSelection>
                          <SelectionItemSelection>
                            {item?.alternateItems?.length > 0 ?
                              item?.alternateItems.map((altItem, ind) => (
                                <Label className="box-menu-radio" style={{ borderTop: ind === 0 && ('0px'), cursor: 'pointer' }}>
                                  {altItem?.[languageContentsForPopup?.Description]}
                                  <Checkbox
                                    id={altItem.Id}
                                    checked={selectedItems[item.description]?.[0]['ItemId']?.includes(altItem.Id) || false}
                                    onChange={() => handleCheckboxChange(item.id, item.description, altItem, item.quantity)}
                                  />
                                </Label>
                              ))
                              :
                              <Label className="box-menu-radio" style={{ borderTop: '0px', cursor: 'pointer' }} key={item.id}>
                                {item?.[languageContents?.description]}
                                <Checkbox
                                  id={item.id}
                                  checked={selectedItems[item.description]?.some(selectedItem => selectedItem.ItemId === item.id) || false}
                                  onChange={() => handleDirectSelection(item)}
                                />
                              </Label>
                            }
                          </SelectionItemSelection >
                        </>
                      ))
                      }
                    </SelectionSelection>
                    <ButtonSection>
                      <div className='main-div'>
                        <div className='clear-btn' onClick={() => setIsModalOpen(!isModalOpen)}>Clear</div>
                        <div className='add-btn' onClick={handleConcessionComboOnServer}>Add</div>
                      </div>
                    </ButtonSection>
                  </Modal>
                }
              </div>
            )
            :
            (
              <>
                {
                  summeDetails &&
                  <SelectionMobileBoxMenu className={isExpanded ? '_expanded' : ''}>
                    <div
                      className="b-selection-total-mob__mask"
                      aria-hidden
                    />
                    <div
                      className="b-selection-total-mob__info-wrapper"
                      aria-hidden
                      style={{
                        flexDirection: 'column'
                      }}
                    >
                      <MainSectionMobileView>
                        <div className='sub-menu'>
                          <p className='title'>Select Item</p>
                          <span className='cross-img'><img src={CrossIcon} alt="crossIcon" onClick={handleSummeDetail} /></span>
                        </div>
                      </MainSectionMobileView>
                      <MainSection>
                        <SubSection>
                          <SubSection1>
                            <div className='image-section'>
                              <Image className="rewardImage" src={`${endpoint}${img}`} />
                            </div>
                            <div className='content-section'>
                              <div className='content-heading'>{description}</div>
                              {comboItemData?.length && comboItemData?.map((item, index) => (
                                <div className='content-subHeading'>
                                  <div>{quantity * item.quantity} X {item?.[languageContents?.description]}</div>
                                </div>
                              ))}
                            </div>
                          </SubSection1>
                        </SubSection>
                      </MainSection>
                      <SelectionSelection>
                        {comboItemData?.length && comboItemData?.map((item, index) => (
                          <>
                            <SelectionHeadingSelection key={item?.idx} style={{ marginTop: index === 0 ? "0px" : "24px" }}>
                              <div className='header-section'>
                                <div className='number-section'><span className='number-sub-section'>{index + 1}</span></div>
                                <span className='heading-content-section'>
                                  <p className='heading-content-subSection1'>{item?.[languageContents?.description]}</p>
                                  <p className='heading-content-subSection2'>only {item.quantity} item</p>
                                </span>
                              </div>
                            </SelectionHeadingSelection>
                            <SelectionItemSelection>
                              {item?.alternateItems?.length > 0 ?
                                item?.alternateItems.map((altItem, ind) => (
                                  <Label className="box-menu-radio" style={{ borderTop: ind === 0 && ('0px'), cursor: 'pointer' }}>
                                    {altItem?.[languageContentsForPopup?.Description]}
                                    <Checkbox
                                      id={altItem.Id}
                                      checked={selectedItems[item.description]?.[0]['ItemId']?.includes(altItem.Id) || false}
                                      onChange={() => handleCheckboxChange(item.id, item.description, altItem, item.quantity)}
                                    />
                                  </Label>
                                ))
                                :
                                <Label className="box-menu-radio" style={{ borderTop: '0px', cursor: 'pointer' }} key={item.id}>
                                  {item?.[languageContents?.description]}
                                  <Checkbox
                                    id={item.id}
                                    checked={selectedItems[item.description]?.some(selectedItem => selectedItem.ItemId === item.id) || false}
                                    onChange={() => handleDirectSelection(item)}
                                  />
                                </Label>
                              }
                            </SelectionItemSelection >
                          </>
                        ))
                        }
                      </SelectionSelection>
                      <div className='total-section-box-menu'>
                        <SubSection2>
                          <div>
                            <div className='total-section-mobile-view'>
                              <div className='boxMenu_inputField'>
                                <InputNumber
                                  min={1}
                                  value={quantity || 0}
                                  onChange={updateItemQuantity(selectedItemsData, comboId?.itemId)}
                                />
                              </div>
                              <div className='total-sub-section'>
                                <span className='sub-section1'>{quantity} X {currencyForCountry} {priceInCents}</span>
                                <span className='sub-section2'>{currencyForCountry} {quantity * priceInCents}</span>
                              </div>
                            </div>
                          </div>
                        </SubSection2>
                        <SelectionSelection>
                          {/* <div className="total-sub-section">{renderSelectedItems()}</div>
                          {Object.keys(selectedItems)?.length > 0 && <div className="price-in-mobile-view"><strong>£ {calculateTotalPrice()}</strong></div>} */}
                        </SelectionSelection>
                        <RowButton style={{
                          padding: '16px 20px'
                        }}>
                          <Button isPrimary
                            onClick={handleConcessionComboOnServer}
                          >
                            Add
                          </Button>
                        </RowButton>
                      </div>
                    </div>
                  </SelectionMobileBoxMenu>
                }
              </>
            )
        }
      </Container>
    </SectionEntities >
  )
}

export default BoxMenu;
