/* eslint-disable no-self-compare */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Svg } from 'app/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import BlockNav from './components/BlockNav';
import BlockControl from './components/BlockControl';
import { HeaderTag, LogoLink, BlockMain, CinemaDetailLogo } from './styles';
import MobileNav from './components/MobileNav';

const propTypes = {
  cinemas: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  savedLocation: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  isVisible: PropTypes.bool.isRequired
};

const defaultProps = {
  userName: null
};

const Header = ({ cinemas, locations, savedLocation, isLoggedIn, userName, isVisible, pathname }) => {
  const location = useLocation();
  
  return (
    <HeaderTag>
      <Container>
        {
        location.pathname?.split('/')[4] === "tickets" || location.pathname?.split('/')[4] === "seats" || location.pathname?.split('/')[4] === "checkout" ?
        <MobileNav
          cinemas={cinemas}
          locations={locations}
          savedLocation={savedLocation}
          isLoggedIn={isLoggedIn}
          pathname={pathname}
          userName={userName}
        />
        :
        <></>
        }
        
        
        <LogoLink className='logo' to="/">                  
          <Svg id="logo-new" />
          <Svg id="logo-new-md" />        
        </LogoLink>

        <BlockMain isVisible={isVisible} isLoggedIn={isLoggedIn}>
          <BlockNav
            cinemas={cinemas}
            locations={locations}
            savedLocation={savedLocation}
            isLoggedIn={isLoggedIn}
            userName={userName}
          />
          <BlockControl isLoggedIn={isLoggedIn} userName={userName} />
        </BlockMain>
      </Container>
    </HeaderTag>
  )
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
export default Header;
